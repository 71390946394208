import Layout from "app/components/layout";
import Sprite from "app/components/sprite";
import { ZBNI_CUSTOMER_SUPPORT } from "app/config/constants";
import Link from "app/components/i18n/Link";

const Error404Page = () => (
  <Layout headerIsOnTop={false} enableHeaderShrinkingOnScroll={false}>
    <section className="bg-radial-white-gray relative m-auto w-full font-montserrat">
      <div className="zbooni-layout flex h-screen">
        <div className="m-auto flex flex-col text-center">
          <Sprite
            name="sad-zbooni"
            className="m-auto w-24 md:mb-20 md:w-auto"
          />
          <p className="mb-8 text-3xl font-semibold">
            Ooops! We couldn’t find what you were looking for...
          </p>
          <p className="mb-20 text-lg font-normal">
            Looks like this page is missing. If you still need help,{" "}
            <a
              className="text-blue"
              href={`${ZBNI_CUSTOMER_SUPPORT}&text=Hello%21%20I%20am%20interested%20in%20learning%20more%20about%20Zbooni.`}
              id="cta_404_contactUs"
            >
              contact us.
            </a>
          </p>
          <div className="mx-auto">
            <Link to="/" id="cta_404_goToHomepage">
              <button className="h-[50px] w-[202px] rounded-full bg-primary">
                <p className="bg-gradient-to-r from-yellow-dark via-green to-cyan bg-clip-text font-bold text-transparent">
                  Go to homepage
                </p>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Error404Page;
